body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: Helvetica, Arial, sans-serif;
  background-color: #f2f2f2;
}

.slideout-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 256px;
  min-height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 0;
  display: none;
}

.slideout-menu-left {
  left: 0;
}

.slideout-menu-right {
  right: 0;
}

.slideout-panel {
  position: relative;
  z-index: 1;
  will-change: transform;
  background-color: #FFF; /* A background-color is required */
  min-height: 100vh;
}

.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
  overflow: hidden;
}

.slideout-open .slideout-menu {
  display: block;
}

.toggle-button {
  position: fixed;
  left: 10px;
  top: 10px;
  font-size: 20px;
}

audio {
  position: fixed;
  left: 10px;
  bottom: 10px;
  width: calc(100% - 20px);
}

.show {
  opacity: 1;
}

.hide {
  opacity: 0;
  transition: opacity 400ms;
}

.removed {
  display: none;
}

.iconImg {
  width: 16px;
  height: 16px;
}

#micButton {
  cursor: pointer;
  border-radius: 1em;
}

.micIsRecording {
  filter: invert(1);
  background-color: #00FFFF; /* red inverse */
}

input[type=number]{
    width: 32px;
}

input[type=file]{
    width: 220px;
}

#settingsMenu {
  margin: 10px;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0px;
}
h5, h6 {
  margin-top: 8px;
}
p {
  margin: 0px;
  font-size: 12px;
}

.hEqualSpacing {
  display: flex;
  justify-content: space-between;
}

#frequencySelection {
  margin-left: 16px;
  margin-right: 32px;
}

.freqSlider {
  margin-top: 2px;
  margin-bottom: 36px;
}

.noUi-horizontal .noUi-handle-lower .noUi-tooltip {
  bottom: -130%;
}

.noUi-horizontal .noUi-handle-upper .noUi-tooltip {
  bottom: -130%;
}

.noUi-value {
  font-size: 12px;
}

#linkSection {
  padding-bottom: 10px;
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
